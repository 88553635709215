import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import type { ImageProps } from '@marty-js/design/src/atoms/types';
import { getImageUrl, getSrcSet, ImageSrc } from '@marty-js/design/src/utils/image.service';
import { useSdkConfig } from '../../utils/config';
import { useImageIsEager } from '../../utils/imageContext';

const ShortClubic = dynamic(() => import('@marty-js/design/src/icons/clubicShort'));

const shining = keyframes`
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
`;

const SVG_SIZE = '50px';

const ClippingSvg = styled(ShortClubic)`
  animation: ${shining} ease-in-out infinite alternate 600ms 200ms;
  width: min(90%, ${SVG_SIZE});
  height: min(90%, ${SVG_SIZE});
  background-color: var(--background-color);
  color: ${(props) => props.theme.colors.foreground};
`;

function LoadingImage({ 'data-testid': dataTestId }: React.PropsWithChildren<{ 'data-testid': string }>) {
  return <ClippingSvg width={50} height={50} data-testid={dataTestId} aria-busy />;
}

const LoadingContainer = styled.div<{
  desktopWidth: number;
  desktopHeight: number;
  mobileWidth: number;
  mobileHeight: number;
}>`
  padding-top: calc((${(props) => props.mobileHeight / props.mobileWidth} * 100% - ${SVG_SIZE}) / 2);
  padding-bottom: calc((${(props) => props.mobileHeight / props.mobileWidth} * 100% - ${SVG_SIZE}) / 2);

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css<{
      mobileWidth: number;
      mobileHeight: number;
      desktopWidth: number;
      desktopHeight: number;
    }>`
      padding-top: calc((${(props) => props.desktopHeight / props.desktopWidth} * 100% - ${SVG_SIZE}) / 2);
      padding-bottom: calc((${(props) => props.desktopHeight / props.desktopWidth} * 100% - ${SVG_SIZE}) / 2);
    `,
  )}

  text-align: center;
`;

const StyledImage = styled.img<{
  disableRadius?: boolean;
}>`
  border-radius: ${(props) => (props.disableRadius ? '0' : '6px')};
  width: 100%;
  color: transparent;
  height: 100%;
  max-width: 100%;
  vertical-align: middle;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css<{
      mobileWidth: number;
      mobileHeight: number;
      desktopWidth: number;
      desktopHeight: number;
      disableRadius?: boolean;
    }>`
      border-radius: ${(props) => (props.disableRadius ? '0' : '10px')};
    `,
  )}
`;

const fadeIn = keyframes`
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
`;
const StyledPicture = styled.picture<{
  mobileWidth: number;
  mobileHeight: number;
  desktopWidth: number;
  desktopHeight: number;
}>`
  position: relative;
  width: ${(props) => props.mobileWidth}px;
  height: ${(props) => props.mobileHeight}px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css<{
      mobileWidth: number;
      mobileHeight: number;
      desktopWidth: number;
      desktopHeight: number;
    }>`
      width: ${(props) => props.desktopWidth}px;
      height: ${(props) => props.desktopHeight}px;
    `,
  )}

  &.isEager {
    background-color: transparent;
    ${StyledImage} {
      width: 100% !important;
      height: 100% !important;
      animation: none !important;
    }
  }

  &.loaded {
    background-color: transparent;
    ${StyledImage} {
      width: 100%;
      height: 100%;
      animation: ${fadeIn} ease-in-out 800ms;
    }
  }
`;

type SourceType = {
  type?: string;
  media: string;
  sizes: string;
  srcSet: ImageSrc[];
};

function Preload({ sources }: { sources: SourceType[] }) {
  return (
    <Head>
      {sources.map((source) => {
        const imageSrcSet = source?.srcSet?.map((srcSet) => `${srcSet.url} ${srcSet.pixelDensity}x`).join(', ');

        return source ? (
          <link
            key={imageSrcSet + source.media}
            rel="preload"
            // @ts-ignore
            imageSrcSet={imageSrcSet}
            type={source.type}
            as="image"
            media={source.media}
          />
        ) : null;
      })}
    </Head>
  );
}

export function Image({
  imageId,
  lazy = true,
  alt = 'Clubic',
  className,
  mobileWidth,
  mobileHeight,
  desktopWidth,
  desktopHeight,
  disableRadius = false,
  extension,
  title,
  maxWidth,
}: React.PropsWithChildren<ImageProps>) {
  const isEager = useImageIsEager({
    mobileWidth,
    mobileHeight,
    desktopWidth,
    desktopHeight,
    imageId,
  });

  const isLazy = lazy && !isEager;

  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [isInViewport, setIsVisibleInViewport] = useState(false);
  const sdkConfig = useSdkConfig();

  const defaultWidth = desktopWidth > 1200 ? 1200 : desktopWidth;
  const defaultHeight = Math.ceil((defaultWidth * desktopHeight) / desktopWidth);

  const resizeMobileWidth = mobileWidth > DEVICE_SIZE.MEDIUM ? DEVICE_SIZE.MEDIUM : mobileWidth;
  const resizeMobileHeight =
    resizeMobileWidth === mobileWidth ? mobileHeight : Math.ceil((resizeMobileWidth * desktopHeight) / desktopWidth);

  const resizeTabletWidth = mobileWidth > DEVICE_SIZE.LARGE ? DEVICE_SIZE.LARGE : mobileWidth;
  const resizeTabletHeight =
    resizeTabletWidth === mobileWidth ? mobileHeight : Math.ceil((resizeTabletWidth * desktopHeight) / desktopWidth);

  const resizeDesktopWidth = desktopWidth > 1200 ? 1200 : desktopWidth;

  const resizeDesktopHeight =
    resizeDesktopWidth === desktopWidth
      ? desktopHeight
      : Math.ceil((resizeDesktopWidth * desktopHeight) / desktopWidth);

  const src = useMemo(
    () =>
      getImageUrl({
        hostname: sdkConfig.imageApiHostname,
        imageId,
        width: defaultWidth,
        height: defaultHeight,
        extension,
        title,
      }),
    [imageId, defaultWidth, defaultHeight, sdkConfig, extension, title],
  );

  const srcSet = useMemo(
    () =>
      getSrcSet({
        hostname: sdkConfig.imageApiHostname,
        imageId,
        mobileWidth: resizeMobileWidth,
        mobileHeight: resizeMobileHeight,
        tabletWidth: resizeTabletWidth,
        tabletHeight: resizeTabletHeight,
        desktopWidth: resizeDesktopWidth,
        desktopHeight: resizeDesktopHeight,
        extension,
        title,
        maxWidth,
      }),
    [
      imageId,
      resizeMobileWidth,
      resizeMobileHeight,
      resizeTabletWidth,
      resizeTabletHeight,
      resizeDesktopWidth,
      resizeDesktopHeight,
      sdkConfig,
      title,
      extension,
      maxWidth,
    ],
  );

  const srcSetWebp = useMemo(
    () =>
      getSrcSet({
        hostname: sdkConfig.imageApiHostname,
        imageId,
        mobileWidth: resizeMobileWidth,
        mobileHeight: resizeMobileHeight,
        tabletWidth: resizeTabletWidth,
        tabletHeight: resizeTabletHeight,
        desktopWidth: resizeDesktopWidth,
        desktopHeight: resizeDesktopHeight,
        extension: 'webp',
        title,
        maxWidth,
      }),
    [
      imageId,
      resizeMobileWidth,
      resizeMobileHeight,
      resizeTabletWidth,
      resizeTabletHeight,
      resizeDesktopWidth,
      resizeDesktopHeight,
      sdkConfig,
      title,
      maxWidth,
    ],
  );

  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    let observer: IntersectionObserver;
    let didCancel = false;
    const imageRef = imgRef.current;

    if (!imageRef || !isLazy) {
      return () => {};
    }

    if (window.IntersectionObserver) {
      observer = new window.IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (!didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
              setIsVisibleInViewport(true);
            }
          });
        },
        {
          rootMargin: '75%',
          threshold: 0.01,
        },
      );

      observer.observe(imageRef);
    }

    return () => {
      didCancel = true;
      if (observer) {
        observer.unobserve(imageRef);
      }
    };
  }, [imgRef, isInViewport, isLazy]);

  const handleOnLoad = () => {
    setLoaded(true);
  };

  const handleOnError = () => {
    setError(true);
  };

  const visible = !isLazy || isInViewport;

  const sources: SourceType[] = [
    {
      media: `(max-width: ${DEVICE_SIZE.MEDIUM}px)`,
      sizes: `${resizeMobileWidth}px`,
      srcSet: srcSet.mobile,
    },
    {
      media: `(min-width: ${DEVICE_SIZE.MEDIUM + 1}px) and (max-width: ${DEVICE_SIZE.LARGE}px)`,
      sizes: `${resizeTabletWidth}px`,
      srcSet: srcSet.tablet,
    },
    {
      media: `(min-width: ${DEVICE_SIZE.LARGE + 1}px)`,
      sizes: `${resizeDesktopWidth}px`,
      srcSet: srcSet.desktop,
    },
  ];

  const sourcesWebp: SourceType[] = [
    {
      media: `(max-width: ${DEVICE_SIZE.MEDIUM}px)`,
      sizes: `${resizeMobileWidth}px`,
      srcSet: srcSetWebp.mobile,
      type: 'image/webp',
    },
    {
      media: `(min-width: ${DEVICE_SIZE.MEDIUM + 1}px) and (max-width: ${DEVICE_SIZE.LARGE}px)`,
      sizes: `${resizeTabletWidth}px`,
      srcSet: srcSetWebp.tablet,
      type: 'image/webp',
    },
    {
      media: `(min-width: ${DEVICE_SIZE.LARGE + 1}px)`,
      sizes: `${resizeDesktopWidth}px`,
      srcSet: srcSetWebp.desktop,
      type: 'image/webp',
    },
  ];

  const source0 = JSON.stringify(sources[0].srcSet);
  const source1 = JSON.stringify(sources[1].srcSet);
  const source2 = JSON.stringify(sources[2].srcSet);
  if (source0 === source2) {
    delete sources[2];
    delete sources[1];
    delete sources[0].media;
    delete sourcesWebp[2];
    delete sourcesWebp[1];
    delete sourcesWebp[0].media;
  } else if (source0 === source1) {
    sources[0].media = `(max-width: ${DEVICE_SIZE.LARGE}px)`;
    delete sources[1];
    sourcesWebp[0].media = `(max-width: ${DEVICE_SIZE.LARGE}px)`;
    delete sourcesWebp[1];
  } else if (source1 === source2) {
    sources[1].media = `(min-width: ${DEVICE_SIZE.MEDIUM + 1}px)`;
    delete sources[2];
    sourcesWebp[1].media = `(min-width: ${DEVICE_SIZE.MEDIUM + 1}px)`;
    delete sourcesWebp[2];
  }

  return (
    <StyledPicture
      className={classnames(className, { isEager, loaded, error })}
      mobileWidth={resizeMobileWidth}
      mobileHeight={resizeMobileHeight}
      desktopWidth={resizeDesktopWidth}
      desktopHeight={resizeDesktopHeight}
      ref={imgRef}
    >
      {isEager ? <Preload sources={[...sourcesWebp]} /> : null}
      {visible && (
        <>
          {[...sourcesWebp, ...sources].map((source) => {
            return source ? (
              <source
                key={source.srcSet + source.media + source.type}
                media={source.media}
                sizes={source.sizes}
                type={source.type}
                srcSet={source.srcSet.map((srcset) => `${srcset.url} ${srcset.pixelDensity}x`).join(', ')}
              />
            ) : null;
          })}
          <StyledImage
            src={src}
            alt={alt}
            width={resizeDesktopWidth}
            height={resizeDesktopHeight}
            loading={isLazy ? 'lazy' : 'eager'}
            onLoad={isLazy ? handleOnLoad : null}
            onError={handleOnError}
            disableRadius={disableRadius}
          />
        </>
      )}
      {isLazy && imgRef.current && !loaded && !error ? (
        <LoadingContainer
          mobileWidth={resizeMobileWidth}
          mobileHeight={resizeMobileHeight}
          desktopWidth={resizeDesktopWidth}
          desktopHeight={resizeDesktopHeight}
        >
          <LoadingImage data-testid="loading-image" />
        </LoadingContainer>
      ) : null}
      {isLazy && !imgRef.current ? (
        <noscript>
          <StyledImage
            src={src}
            alt={alt}
            width={resizeDesktopWidth}
            height={resizeDesktopHeight}
            loading="lazy"
            disableRadius={disableRadius}
          />
        </noscript>
      ) : null}
    </StyledPicture>
  );
}
