import type { Theme, ThemeColorsPalette } from '../utils/types';

const palette: ThemeColorsPalette = {
  black: '#232728',
  blackAlt: '#212C42',
  darkBlack: '#1b1b1b',
  white: '#FFFFFF',
  lightGrey: '#54617C',
  lightWhite: '#dfe3ea',
  red: '#E4002A',
  redMedium: '#FF5978',
  darkRed: '#AF0524',
  blue: '#3F547E',
  blueMedium: '#99A4BA',
  darkBlue: '#37486D',
  green: '#009e76',
  darkGreen: '#018262',
  grey: '#f5f5f7',
};

export const lightTheme: Theme = {
  isDark: false,
  typography: {
    defaultFont: "'Inter UI', Arial, sans-serif",
    primaryFont: "'Bitter', serif",
  },

  palette,

  colors: {
    background: palette.white,
    foreground: palette.blackAlt,
    medium: palette.blueMedium,
    primary: palette.red,
    primaryContrast: palette.white,
    title: palette.blackAlt,
    titleAlt: palette.blackAlt,
  },

  backgroundTheme: {
    contrast: {
      backgroundColor: palette.grey,
      linkColor: 'inherit',
      textColor: palette.blackAlt,
    },
    flashy: {
      backgroundColor: palette.red,
      linkColor: palette.white,
      textColor: palette.white,
    },
    navy: {
      backgroundColor: palette.blue,
      linkColor: palette.white,
      textColor: palette.white,
    },
    light: {
      backgroundColor: palette.grey,
      textColor: palette.blackAlt,
      linkColor: palette.red,
    },
    transparent: {
      backgroundColor: 'transparent',
      textColor: 'inherit',
      linkColor: 'inherit',
    },
  },
};

export const darkTheme: Theme = {
  isDark: true,
  typography: {
    defaultFont: "'Inter UI', Arial, sans-serif",
    primaryFont: "'Bitter', serif",
  },

  palette,

  colors: {
    background: palette.black,
    foreground: palette.white,
    medium: palette.white,
    primary: palette.redMedium,
    primaryContrast: palette.white,
    title: palette.white,
    titleAlt: palette.white,
  },

  backgroundTheme: {
    contrast: {
      backgroundColor: palette.darkBlack,
      linkColor: 'inherit',
      textColor: palette.white,
    },
    flashy: {
      backgroundColor: palette.redMedium,
      linkColor: palette.white,
      textColor: palette.white,
    },
    navy: {
      backgroundColor: palette.blue,
      linkColor: palette.white,
      textColor: palette.white,
    },
    light: {
      backgroundColor: palette.grey,
      textColor: palette.blackAlt,
      linkColor: palette.redMedium,
    },
    transparent: {
      backgroundColor: 'transparent',
      textColor: 'inherit',
      linkColor: 'inherit',
    },
  },
};
