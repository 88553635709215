import { useEffect } from 'react';
import { trackClick } from '@marty-js/api-sdk/services/matomo';
import { getApiClient } from '@marty-js/api-sdk';

const TrackLinks = (): null => {
  const apiClient = getApiClient();

  useEffect(() => {
    const handleLinkClick = (event: any) => {
      const urlClick = event.target.href;

      if (urlClick && !urlClick.includes('clubic.com')) {
        const userToken = localStorage.getItem('actionToken');

        const trackClickEvent = async () => {
          await trackClick(apiClient, userToken, document.location.href, urlClick);
        };

        trackClickEvent();
      }
    };

    document.querySelectorAll('a').forEach((link) => {
      link.addEventListener('click', handleLinkClick);
    });

    // Cleanup the event listeners on component unmount
    return () => {
      document.querySelectorAll('a').forEach((link) => {
        link.removeEventListener('click', handleLinkClick);
      });
    };
  }, [apiClient]);

  return null;
};

export default TrackLinks;
