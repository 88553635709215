import React from 'react';
import styled from 'styled-components';
import type { LinkProps } from './types';
import { LinkComponent } from '../utils/component';

const StyledLinked = styled.a`
  color: ${(props) => (props.theme.isDark ? '#FF5978' : props.theme.colors.primary)};
`;

const UnstyledLinked = styled.a`
  text-decoration: none;
`;

export const Link = ({ ...props }: React.PropsWithChildren<LinkProps>) => {
  const { href, rel, openInNewWindow, children, stylized } = props;
  const LinkStyle = stylized ? StyledLinked : UnstyledLinked;

  return (
    <LinkStyle
      href={href}
      className=""
      rel={rel?.length ? rel.join(',') : null}
      target={openInNewWindow ? '_blank' : null}
    >
      {children}
    </LinkStyle>
  );
};

export const BaseLink = styled.a`
  text-decoration: none;

  &.mod-primary {
    color: ${(props) => (props.theme.isDark ? '#FF5978' : props.theme.colors.primary)};
  }
`;

export const UndecoratedNavLink = styled(LinkComponent)`
  text-decoration: none;
`;

export const SimpleLink = styled.a`
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
`;

export const ExternalLink = styled(SimpleLink).attrs(() => ({ target: '_blank', rel: 'nofollow noreferrer' }))``;

export const AffiliateLink = styled(SimpleLink).attrs(() => ({
  target: '_blank',
  rel: 'nofollow no-referrer-when-downgrade',
}))``;

export const MinifiedExternalLink = styled(SimpleLink).attrs(() => ({
  target: '_blank',
  rel: 'nofollow noreferrer',
  referrerPolicy: 'no-referrer-when-downgrade',
}))``;
