import { createGlobalStyle } from 'styled-components';
import type { StyledTheme } from './types';

export const InjectRootStyles = createGlobalStyle<StyledTheme>`
  :root {
    --spacer-xs: 7px;
    
    --spacer-s: 15px;
    --spacer-s-fluid: clamp(0.625rem, 0.536rem + 0.446vw, 0.938rem);

    --spacer: 30px;
    --spacer-to-xs: clamp(0.438rem, 0.027rem + 2.054vw, 1.875rem);
    --spacer-fluid: clamp(0.938rem, 0.67rem + 1.339vw, 1.875rem);

    --spacer-m: 45px;
    --spacer-m-to-xs: clamp(0.438rem, -0.241rem + 3.393vw, 2.813rem);
    --spacer-m-to-s: clamp(0.938rem, 0.402rem + 2.679vw, 2.813rem);
    --spacer-m-fluid: clamp(1.875rem, 1.607rem + 1.339vw, 2.813rem);

    --spacer-l: 60px;
    --spacer-l-to-xs: clamp(0.438rem, -0.509rem + 4.732vw, 3.75rem);
    --spacer-l-to-s: clamp(0.938rem, 0.134rem + 4.018vw, 3.75rem);
    --spacer-l-to-default: clamp(1.875rem, 1.339rem + 2.679vw, 3.75rem);
    --spacer-l-fluid: clamp(2.813rem, 2.545rem + 1.339vw, 3.75rem);

    --spacer-xl: 90px;
    --spacer-xl-to-xs: clamp(0.438rem, -1.045rem + 7.411vw, 5.625rem);
    --spacer-xl-to-s: clamp(0.938rem, -0.402rem + 6.696vw, 5.625rem);
    --spacer-xl-to-default: clamp(1.875rem, 0.804rem + 5.357vw, 5.625rem);
    --spacer-xl-to-m: clamp(2.813rem, 2.009rem + 4.018vw, 5.625rem);
    --spacer-xl-fluid: clamp(3.75rem, 3.214rem + 2.679vw, 5.625rem);

    --spacer-em: 1em;
  }

  :root[data-theme='light'] {
      --background-color: white;
      --background-color-sub: #EDF0F4;
      --text-color: black;
      --title-color: ${(props) => props.theme.palette.blackAlt};
      --title-alt: ${(props) => props.theme.palette.blackAlt};
      --title-foreground: ${(props) => props.theme.palette.blackAlt};
  }

  :root[data-theme='dark'] {
      --background-color: #232728;
      --background-color-sub: ${(props) => props.theme.palette.darkBlack};
      --text-color: white;
      --title-color: ${(props) => props.theme.palette.white};
      --title-alt: ${(props) => props.theme.palette.white};
      --title-foreground: ${(props) => props.theme.palette.white};
  }


  html {
    color: var(--text-color);
    background-color: var(--background-color);
    transition: background-color 1s;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    line-height: 1.2;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    font-family: ${(props) => props.theme.typography.defaultFont};
    overflow-x: hidden;
  }

  article aside {
    display: none;
  }
  
  html.no-event {
    &,
    body {
      overflow: hidden;
    }
  }

  a {
    color: inherit;
  }
  
  blockquote {
    font-style: italic;
  }

  @media (prefers-reduced-motion: reduce) {
    *,
    *:hover,
    *:focus {
      &,
      &::before,
      &::after {
        animation: none;
        transition: none;
        scroll-behavior: auto;
      }
   }
  }


`;
